import { useState } from 'react'
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='col-md-12'>
              <a
                href='https://www.google.com.mx/maps/place/Sta.+Cecilia+97,+Coapa,+Cafetales+I,+Coyoac%C3%A1n,+04930+Ciudad+de+M%C3%A9xico,+CDMX/@19.3121268,-99.1080432,21z/data=!4m5!3m4!1s0x85ce018c150aebb9:0x5a8125bdfe5f1070!8m2!3d19.3122463!4d-99.1082?shorturl=1'
                className='btn btn-custom btn-lg page-scroll'
                >
                  Google Maps
                </a>{' '}

                <a
               href='https://api.whatsapp.com/send/?phone=525634760504&text&app_absent=0'
                className='btn btn-custom btn-lg page-scroll'
                >
                  WhatsApp
                </a>{' '}

                <a
               href='https://api.whatsapp.com/send/?phone=525634760504&text&app_absent=0'
                className='btn btn-custom btn-lg page-scroll'
                >
                  Obtén 10% de descuento
                </a>{' '}
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contacto</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Dirección
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Número
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Correo
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container'>
            <h3>
              <a href='https://docs.google.com/document/d/1gc9KpBZeACez7qTRtScFDFxsesKdcUecPYoGZ3IdKW4/edit?usp=sharing' rel='nofollow'>
              Aviso de Privacidad
              </a>
            </h3>
          </div>
        <div className='container text-center'>
          <p>
            &copy; 2022 credo por {' '}
            <a href='https://nidi.contact' rel='nofollow'>
              Nidi
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
